import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import TitleBlock from "../components/blocks/TitleBlock";
import FeatureBlock from "../components/blocks/FeatureBlock";

import baselineMeetingRoom from '@iconify/icons-ic/baseline-meeting-room';
import bedIcon from '@iconify/icons-cil/bed';
import showerIcon from '@iconify/icons-cil/shower';
import outlineKitchen from '@iconify/icons-ic/outline-kitchen';
import userFriends from '@iconify/icons-fa-solid/user-friends';
import hairDryer from '@iconify/icons-mdi/hair-dryer';
import tableChair from '@iconify/icons-mdi/table-chair';
import tvOutline from '@iconify/icons-ion/tv-outline';
import babyIcon from '@iconify/icons-fa-solid/baby';
import wifiIcon from '@iconify/icons-mdi/wifi';
import highchairIcon from '@iconify/icons-vs/highchair';
import baselineLocalParking from '@iconify/icons-ic/baseline-local-parking';
import bedSingleOutline from '@iconify/icons-mdi/bed-single-outline';
import filePaper2Line from '@iconify/icons-ri/file-paper-2-line';
import Photos from "../components/general/Photos";
import ContactInfo from "../components/general/ContactInfo";
import SmallHeader from "../components/SmallHeader";
import {graphql, useStaticQuery} from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Apartament2Osobowy = (props) => {

    const images = useStaticQuery(graphql`
        {
            other: allFile(filter: {relativeDirectory: {eq: "rooms/2-persons"}, name: {ne: "cover"}}) {
                edges{
                    node{
                        id
                        childImageSharp {
                            sizes(maxWidth: 1800){
                                ...GatsbyImageSharpSizes_withWebp
                            }
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
            cover: file(name: {eq: "cover"}, relativeDirectory: {eq: "rooms/2-persons"}) {
                id
                childImageSharp {
                    fluid(quality: 100){
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }`);

    return (
        <>
            <Layout>
                <SEO title="Apartament 2-osobowy"/>

                <SmallHeader
                    title="Apartament 2-osobowy"
                    backgroundName={images.cover}
                    hasButtonStyle={true}
                    price="230-290zł / dobę"
                />

                <Container>
                    <Row>
                        <Col md="6" className="mr-auto ml-auto">
                            <div className="text-center section">
                                <TitleBlock
                                    title="Standard pokoju dwusobowego"
                                    headingLevel="h3"
                                    description="Wszystkie nasze pokoje posiadają aneks kuchenny, łazienkę oraz balkon, z którego na wyższych kondygnacjach roztacza się piękny widok na morze i latarnię morską. Poniżej znajdziecie Państwo szczegółowy wykaz udogodnień i wyposażenia dla wybranego apartamentu."
                                    extendedClasses="color-sea"
                                />
                            </div>
                        </Col>

                    </Row>

                    <Row>
                        <Col md="7" className="mr-auto ml-auto">

                            <div className="section">

                                <Row>
                                    <Col md="6">
                                        <FeatureBlock
                                            icon={userFriends}
                                            text="1-2 osoby"
                                        />
                                    </Col>
                                    <Col md="6">
                                        <FeatureBlock
                                            icon={outlineKitchen}
                                            text="aneks kuchenny (płyta indukcyjna, lodówka, czajnik, naczynia)"
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="6">
                                        <FeatureBlock
                                            icon={baselineMeetingRoom}
                                            text="1 pokój"
                                        />
                                    </Col>
                                    <Col md="6">
                                        <FeatureBlock
                                            icon={showerIcon}
                                            text="łazienka z kabiną prysznicową"
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="6">
                                        <FeatureBlock
                                            icon={bedIcon}
                                            text="łóżko typu double z możliwością rozstawienia do twin"
                                        />
                                    </Col>
                                    <Col md="6">
                                        <FeatureBlock
                                            icon={hairDryer}
                                            text="suszarka"
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="6">
                                        <FeatureBlock
                                            icon={tableChair}
                                            text="balkon ze stolikiem i krzesłami (część balkonów na wyższych kondygnacjach posiada widok na morze i latarnie morską)"
                                        />
                                    </Col>
                                    <Col md="6">
                                        <FeatureBlock
                                            icon={filePaper2Line}
                                            text="ręczniki dla gości"
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="6">
                                        <FeatureBlock
                                            icon={tvOutline}
                                            text="TV (około 130 dostępnych kanałów)"
                                        />
                                    </Col>
                                    <Col md="6">
                                        <FeatureBlock
                                            icon={babyIcon}
                                            text="łóżeczko turystyczne (wymaga rezerwacji)"
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="6">
                                        <FeatureBlock
                                            icon={wifiIcon}
                                            text="dostęp do Internetu wi-fi"
                                        />
                                    </Col>
                                    <Col md="6">
                                        <FeatureBlock
                                            icon={highchairIcon}
                                            text="fotelik do karmienia (wymaga rezerwacji)"
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="6">
                                        <FeatureBlock
                                            icon={baselineLocalParking}
                                            text="parking (dodatkowo płatny)"
                                        />
                                    </Col>
                                    <Col md="6">
                                        <FeatureBlock
                                            icon={bedSingleOutline}
                                            text="opcja dostawki (powyżej 4 r.ż. dodatkowo płatna)"
                                        />
                                    </Col>
                                </Row>
                            </div>

                        </Col>
                    </Row>

                    <Row>
                        <Col md="10" className="mr-auto ml-auto">
                            <div className="section">
                                <Photos
                                    photos={images.other}
                                />
                            </div>

                            <div className="section">
                                <ContactInfo/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </>
    );
};

export default Apartament2Osobowy;
